import { Route, Routes } from "react-router-dom";
import "./App.css";
import MainLayout from "./components/Layout";
import Notification from "./pages/AddNotification";
import Admin from "./pages/Admin";
import Category from "./pages/Category";
import { Downloads } from "./pages/DownloadHistory";
import Home from "./pages/Home";
import LoginForm from "./pages/Login";
import Photo from "./pages/Photo";
import Photos from "./pages/purchaseandsoldrecords/Photos";
import VideosTransactions from "./pages/purchaseandsoldrecords/Videos";
import RedeemRequest from "./pages/RedeemRequest";
import TodayTransaction from "./pages/TodayTransaction";
import Transaction from "./pages/Transaction";
import Upload from "./pages/Upload";
import Users from "./pages/Users";
import Videos from "./pages/Video";

function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <MainLayout>
            <Home />
          </MainLayout>
        }
      />
      <Route path="/login" element={<LoginForm />} />
      <Route
        path="/upload"
        element={
          <MainLayout>
            <Upload />
          </MainLayout>
        }
      />
      <Route
        path="/users"
        element={
          <MainLayout>
            <Users />
          </MainLayout>
        }
      />
      <Route
        path="/transactions"
        element={
          <MainLayout>
            <Transaction />
          </MainLayout>
        }
      />
      <Route
        path="/download-history/:id"
        element={
          <MainLayout>
            <Downloads />
          </MainLayout>
        }
      />
      <Route
        path="/todays-transaction"
        element={
          <MainLayout>
            <TodayTransaction />
          </MainLayout>
        }
      />
      <Route
        path="/photos-transaction/:type"
        element={
          <MainLayout>
            <Photos />
          </MainLayout>
        }
      />
      <Route
        path="/add-notification"
        element={
          <MainLayout>
            <Notification />
          </MainLayout>
        }
      />
      <Route
        path="/video-transaction/:type"
        element={
          <MainLayout>
            <VideosTransactions />
          </MainLayout>
        }
      />

      <Route
        path="/category"
        element={
          <MainLayout>
            <Category />
          </MainLayout>
        }
      />

      <Route
        path="/admin"
        element={
          <MainLayout>
            <Admin />
          </MainLayout>
        }
      />
      <Route
        path="/photos"
        element={
          <MainLayout>
            <Photo />
          </MainLayout>
        }
      />
      <Route
        path="/video"
        element={
          <MainLayout>
            <Videos />
          </MainLayout>
        }
      />
      <Route
        path="/redeem-requests"
        element={
          <MainLayout>
            <RedeemRequest />
          </MainLayout>
        }
      />
    </Routes>
  );
}

export default App;
