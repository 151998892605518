import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addUser,
  completeSalary,
  deleteUser,
  getDownlaods,
  getDownloadHistory,
  getUserPurchaseHistory,
  getUserSellHistory,
  getUserWalletHistory,
  getUsers,
  updateUser,
} from "../utils/api";

import { UploadOutlined } from "@ant-design/icons";

import {
  Table,
  Button,
  Modal,
  Image,
  Typography,
  Form,
  Input,
  message,
  Upload,
  Select,
  DatePicker,
  Popconfirm,
} from "antd";

import useSearch from "../components/Search";
import { Link } from "react-router-dom";
import { useAuth } from "../utils/store";

const Users = () => {
  const user = useAuth((state) => state.user);
  const [page, setPage] = useState(1);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["users"],
    queryFn: () => user?.getUsers && getUsers(page),
  });
  console.log(data, user);
  const { mutate: deleteUserData, isLoading: deleteisLoading2 } = useMutation(
    async (values) => user?.deleteUsers && (await deleteUser(values)),
    {
      onSuccess: () => {
        message.success(`Deleted`);
        refetch();
      },
    }
  );
  const search = useSearch();
  const columns = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "space-around",

              flexDirection: "column",
              gap: 10,
            }}
          >
            {user?.deleteUsers && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => deleteUserData(record._id)}
              >
                <Button
                  danger
                  style={{ marginTop: 10 }}
                  loading={deleteisLoading2}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}

            {user?.userDownloads && (
              <Link to={`/download-history/${record?._id}`}>
                <Button
                  type="primary"
                  loading={deleteisLoading2}
                  style={{
                    width: "100%",
                  }}
                >
                  Downloads
                </Button>
              </Link>
            )}
            {user?.updateUsers && <AddData update={record} refech={refetch} />}
            {user?.userSoldRecords && <UserRecord row={record} type="sold" />}
            {user?.userPurchaseRecord && (
              <UserRecord row={record} type="purchased" />
            )}
            {user?.userWalletHistory && <UserWalletHistory row={record} />}
          </div>
        );
      },
    },

    {
      title: "Image",
      dataIndex: "image",
      render(value) {
        return <Image src={value} width={100} height={100} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      ...search("name"),
    },
    {
      title: "Email",
      dataIndex: "email",
      ...search("email"),
    },
    {
      title: "Phone Number",
      dataIndex: "phoneNumber",
      ...search("phoneNumber"),
    },
    {
      title: "Wallet",
      dataIndex: "wallet",
    },
    {
      title: "Date of Birth",
      dataIndex: "dob",
    },
  ];

  useEffect(() => {
    refetch();
  }, [page]);

  return (
    <div
      style={{
        padding: 24,
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Users
        </Typography>
        {user.addUsers && <AddData refech={refetch} />}
      </div>
      <Table
        footer={
          user?.getUsers
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data?.users}
        loading={isLoading}
        scroll={{ x: 900 }}
        pagination={{
          total: data?.totalData,
          pageSize: 20,
          onChange: (e) => {
            setPage(e);
          },
          pageSizeOptions: ["20"],
        }}
      />
    </div>
  );
};

export default Users;

const UserWalletHistory = ({ row }) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["user-wallet-history", row._id, visible],
    queryFn: () => visible && getUserWalletHistory(row._id),
  });
  console.log(data);

  return (
    <div>
      <Button
        style={{
          width: "100%",
        }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        Wallet History {data?.wallet}
      </Button>
      <Modal
        title={"Wallet History"}
        confirmLoading={isLoading}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
        width={800}
      >
        <Table
          dataSource={data?.history}
          columns={[
            {
              title: "Title",
              dataIndex: "title",
            },
            {
              title: "Type",
              dataIndex: "type",
            },
            {
              title: "Date",
              dataIndex: "date",
              render(value) {
                return (
                  <span>
                    {new Date(value).toLocaleDateString()}-{" "}
                    {new Date(value).toLocaleTimeString()}
                  </span>
                );
              },
            },
            {
              title: "Amount",
              dataIndex: "amount",
            },
          ]}
        />
      </Modal>
    </div>
  );
};

const UserRecord = ({ row, type }) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["user-record", row._id, visible],
    queryFn: () =>
      visible &&
      (type === "sold"
        ? getUserSellHistory(row._id)
        : getUserPurchaseHistory(row._id)),
  });
  console.log(data);

  return (
    <div>
      <Button
        style={{
          width: "100%",
        }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        {type === "sold" ? "Sold" : "Purchased"}
      </Button>
      <Modal
        title={type === "sold" ? "Sold" : "Purchased"}
        confirmLoading={isLoading}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <h1 style={{ textAlign: "center", fontSize: "20px" }}>Photos</h1>
        <Table
          dataSource={data?.photos}
          columns={[
            {
              title: "Image",
              dataIndex: ["photo", "low"],
              render(value) {
                return <Image src={value} width={100} height={100} />;
              },
            },
            {
              title: "Date",
              dataIndex: "createdAt",
              render(value) {
                return (
                  <span>
                    {new Date(value).toLocaleDateString()}-{" "}
                    {new Date(value).toLocaleTimeString()}
                  </span>
                );
              },
            },
            {
              title: "Amount",
              dataIndex: "amount",
            },
          ]}
        />
        <h1 style={{ textAlign: "center", fontSize: "20px" }}>Videos</h1>
        <Table
          dataSource={data?.videos}
          columns={[
            {
              title: "Image",
              dataIndex: ["video", "thumbnail"],
              render(value) {
                return <Image src={value} width={100} height={100} />;
              },
            },
            {
              title: "Date",
              dataIndex: "createdAt",
              render(value) {
                return (
                  <span>
                    {new Date(value).toLocaleDateString()}-{" "}
                    {new Date(value).toLocaleTimeString()}
                  </span>
                );
              },
            },
            {
              title: "Amount",
              dataIndex: "amount",
            },
          ]}
        />
      </Modal>
    </div>
  );
};

const AddData = ({
  refech,

  update,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("rewardType", form);

  const { mutate, isLoading } = useMutation(
    async (values) =>
      update ? await updateUser(update._id, values) : await addUser(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();
        refech();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );
  console.log(update);
  return (
    <div>
      {contextHolder}
      <Button
        type="primary"
        onClick={() => setVisible(true)}
        style={{
          width: "100%",
        }}
      >
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update User" : "Add User"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 9 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              name: update.name,
              email: update.email,
              phoneNumber: update.phoneNumber,
              dob: update.dob,
              wallet: update.wallet,
              aadharCardNumber: update.aadharCardNumber,
              accountNumber: update.accountNumber,
              bankName: update.bankName,
              ifscCode: update.ifscCode,
              pancardNumber: update.pancardNumber,
              upiId: update.upiId,
            }
          }
          onFinish={(values) => {
            console.log(values);

            const data = new FormData();
            if (values?.image && values?.image?.file?.originFileObj) {
              data.append("image", values.image.file.originFileObj);
            }

            data.append("name", values.name);
            data.append("email", values.email);
            if (!values?.phoneNumber?.includes("+91")) {
              data.append("phoneNumber", "+91" + values.phoneNumber);
            }

            data.append("dob", values.dob);
            data.append("wallet", values.wallet);

            mutate(data);
          }}
        >
          {" "}
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Please select name!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            required
            rules={[
              {
                required: true,
                message: "Please select email!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Aadhar Card Number"
            name="aadharCardNumber"
            required
          >
            <Input />
          </Form.Item>
          <Form.Item label="Account Number" name="accountNumber" required>
            <Input />
          </Form.Item>
          <Form.Item label="Branch Name" name="branchName" required>
            <Input />
          </Form.Item>
          <Form.Item label="Bank Name" name="bankName" required>
            <Input />
          </Form.Item>
          <Form.Item label="Ifsc Code" name="ifscCode" required>
            <Input />
          </Form.Item>
          <Form.Item label="Pan Card Number" name="pancardNumber" required>
            <Input />
          </Form.Item>
          <Form.Item label="UPI Id" name="upiId" required>
            <Input />
          </Form.Item>
          <Form.Item
            label="Dob"
            name="dob"
            required
            rules={[
              {
                required: true,
                message: "Please select dob!",
              },
            ]}
          >
            <Input type="date" />
          </Form.Item>
          <Form.Item
            label="Image"
            name="image"
            required
            rules={
              !update
                ? [
                    {
                      required: true,
                      message: "Please input your image!",
                    },
                  ]
                : []
            }
          >
            <Upload name="image">
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            required
            rules={[
              {
                required: true,
                message: "Please input your phone number!",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Wallet"
            name="wallet"
            required
            rules={[
              {
                required: true,
                message: "Please input your wallet!",
              },
            ]}
          >
            <Input type="number" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
