"use client";

import { Button, Form, Upload } from "antd";
import { UploadIcon } from "lucide-react";
import React, { useEffect } from "react";
import { MediaUploaderVimeo } from "../utils/media-uploader-vimeo";

const MediaUploader = ({ type, update, name, label, videoData }) => {
  const [date, setDate] = React.useState("0");

  useEffect(() => {
    setDate(Date.now());
  }, []);

  console.log(date);
  return (
    <Form.Item
      label={label}
      name={name}
      required
      rules={
        !update
          ? [
              {
                required: true,
                message: "Please input !",
              },
            ]
          : []
      }
    >
      <UploaderVimeo type={type} label={label} videoData={videoData} />
    </Form.Item>
  );
};

export default MediaUploader;

export const UploaderVimeo = ({
  type,
  label,
  onUpload,
  onChange,
  videoData,
}) => {
  return (
    <Upload
      accept={
        type === "srt"
          ? ".srt"
          : type === "photos"
          ? "image/*"
          : type === "videos"
          ? "video/*"
          : type === "audios"
          ? "audio/*"
          : type === "documents"
          ? "application/pdf"
          : type === "both"
          ? "image/*, video/*"
          : ""
      }
      maxCount={1}
      progress={{
        strokeColor: {
          "0%": "#108ee9",
          "100%": "#87d068",
        },
        strokeWidth: 3,
        format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
      }}
      customRequest={async ({ file, onSuccess, onProgress, onError }) => {
        const uploader = new MediaUploaderVimeo();
        const videoMeta = await uploader.upload(
          file,
          (bytesUploaded, bytesTotal) => {
            onProgress({ percent: (bytesUploaded * 100) / bytesTotal });
          }
        );
        console.log(videoMeta);

        onSuccess({
          url: videoMeta?.id?.data?.files?.find(
            (item) => item.quality === "hls"
          )?.link,
        });
        onUpload &&
          onUpload(
            videoMeta?.id?.data?.files?.find((item) => item.quality === "hls")
              ?.link
          );

        videoData(videoMeta);
      }}
      onChange={({ file, fileList }) => {
        onChange && onChange(file);
      }}
    >
      <Button icon={<UploadIcon size={16} />}>Upload {label}</Button>
    </Upload>
  );
};
