import { useMutation, useQuery } from "@tanstack/react-query";

import { Table, Image, Typography, Button, message } from "antd";

import { Link, useParams } from "react-router-dom";

import { useAuth } from "../../utils/store";
import { deleteCategory, getTransactions } from "../../utils/api";
import useSearch from "../../components/Search";

const Photos = () => {
  const user = useAuth((state) => state.user);
  const { type } = useParams();
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["transactions"],
    queryFn: () =>
      user?.photoPurchaseRecords || user?.photoSoldRecords
        ? getTransactions()
        : [],
  });

  const search = useSearch();

  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => await deleteCategory(values),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );

  console.log(data);

  const photosColumns = [
    {
      title: "Image",
      dataIndex: ["photo", "low"],

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "User",
      dataIndex: ["user", "name"],
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phoneNumber"],
    },
    {
      title: "Amount (in ₹)",
      dataIndex: "amount",
      render(value, record) {
        return (
          <span>
            {type === "sold"
              ? record.amount - record.charges
              : record.amount + record.charges}
          </span>
        );
      },
    },

    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render(value, record) {
        //date with time
        return (
          <span>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </span>
        );
      },
    },
  ];
  console.log(user);
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}

      {type === "sold" ? (
        <Table
          footer={
            user?.photoSoldRecords
              ? () => null
              : () => (
                  <Typography.Text type="danger" strong>
                    You don't have permission to view this page
                  </Typography.Text>
                )
          }
          title={() => (
            <Typography
              style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
            >
              Photo sold
            </Typography>
          )}
          columns={photosColumns}
          dataSource={user?.photoSoldRecords ? data?.photoSold : []}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      ) : (
        <Table
          footer={
            user?.photoPurchaseRecords
              ? () => null
              : () => (
                  <Typography.Text type="danger" strong>
                    You don't have permission to view this page
                  </Typography.Text>
                )
          }
          title={() => (
            <Typography
              style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
            >
              Photo Purchase
            </Typography>
          )}
          columns={photosColumns}
          dataSource={user?.photoPurchaseRecords ? data?.photoPurchase : []}
          loading={isLoading}
          scroll={{ x: 600 }}
        />
      )}
    </div>
  );
};

export default Photos;
