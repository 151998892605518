import axios from "axios";
import * as tus from "tus-js-client";

const VIMEO_ACCESS_TOKEN = "b1b42a75eaad2ee7988afd31b46e9739";
const VIMEO_API_URL = "https://api.vimeo.com/me/videos";

const handler = async (vs) => {
  const size = vs;
  const body = {
    upload: {
      approach: "tus",
      size,
    },
    privacy: {
      view: "unlisted",
    },
    embed: {
      color: "#4338CA",
    },
  };
  const headers = {
    Authorization: `bearer ${VIMEO_ACCESS_TOKEN}`,
    "Content-Type": "application/json",
    Accept: "application/vnd.vimeo.*+json;version=3.4",
  };
  const response = await axios.post(VIMEO_API_URL, body, { headers });

  return response.data;
};

export default handler;

export class MediaUploaderVimeo {
  async upload(file, onProgress) {
    const presignedLinkResponse = await handler(file.size);
    console.log(presignedLinkResponse);
    const uploadURI = presignedLinkResponse.upload.upload_link;
    const vimeoVideoLink = presignedLinkResponse.uri;
    const vimeoId = vimeoVideoLink.split("/").slice(-1)[0];

    const getPlayLink = async () => {
      try {
        const response = await axios.get(
          `https://api.vimeo.com/videos/${vimeoId}`,
          {
            headers: {
              Authorization: `Bearer ${VIMEO_ACCESS_TOKEN}`,
            },
          }
        );

        if (
          response?.data?.files?.find((item) => item.quality === "hls")?.link
        ) {
          return response;
        } else {
          return new Promise((resolve) =>
            setTimeout(async () => resolve(await getPlayLink()), 10000)
          );
        }
      } catch (error) {
        console.error("Error fetching Vimeo play link:", error);
        throw new Error("Failed to fetch Vimeo play link");
      }
    };

    return new Promise((resolve, reject) => {
      const uploader = new tus.Upload(file, {
        uploadUrl: uploadURI,
        endpoint: uploadURI,
        onError: (error) => {
          reject(error);
        },
        onProgress: (bytesUploaded, bytesTotal) => {
          onProgress?.(bytesUploaded, bytesTotal);
        },
        onSuccess: async () => {
          const playLink = await getPlayLink();
          if (playLink) {
            resolve({
              provider: "vimeo",
              id: playLink,
            });
          }
        },
      });
      uploader.start();
    });
  }
}
