import { useQuery } from "@tanstack/react-query";
import { getDownlaods, getDownloadHistory } from "../utils/api";
import { useState } from "react";
import { Button, Image, Modal, Table } from "antd";
import { useParams } from "react-router-dom";

export const Downloads = () => {
  const { id } = useParams();
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["downloads", id],
    queryFn: () => getDownlaods(id),
  });
  console.log(data);

  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <h1 style={{ fontSize: "20px" }}>Photos</h1>
      <Table
        loading={isLoading}
        dataSource={data?.photos}
        columns={[
          {
            title: "Image",
            dataIndex: "low",
            render(value) {
              return <Image src={value} width={100} height={100} />;
            },
          },
          {
            title: "Date",
            dataIndex: "createdAt",
            render(value) {
              return <span>{new Date(value).toLocaleDateString()}</span>;
            },
          },
          {
            title: "History",
            dataIndex: "_id",
            render(value) {
              return <DownloadHistory row={id} id={value} />;
            },
          },
        ]}
      />
      <h1 style={{ fontSize: "20px" }}>Videos</h1>
      <Table
        loading={isLoading}
        dataSource={data?.videos}
        columns={[
          {
            title: "Image",
            dataIndex: "thumbnail",
            render(value) {
              return <Image src={value} width={100} height={100} />;
            },
          },
          {
            title: "Date",
            dataIndex: "createdAt",
            render(value) {
              return <span>{new Date(value).toLocaleDateString()}</span>;
            },
          },
          {
            title: "History",
            dataIndex: "_id",
            render(value) {
              return <DownloadHistory user={id} id={value} />;
            },
          },
        ]}
      />
    </div>
  );
};

const DownloadHistory = ({ user, id }) => {
  const [visible, setVisible] = useState(false);
  const { data, isLoading } = useQuery({
    queryKey: ["downloads", user, visible, id],
    queryFn: () =>
      visible &&
      getDownloadHistory({
        id: id,
        userId: user,
      }),
  });
  console.log(id);
  return (
    <div>
      <Button
        style={{
          width: "100%",
        }}
        type="primary"
        onClick={() => setVisible(true)}
      >
        History
      </Button>
      <Modal
        title="History"
        confirmLoading={isLoading}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Table
          dataSource={data?.history}
          columns={[
            {
              title: "IP",
              dataIndex: "ip",
              render(value) {
                return <span>{value}</span>;
              },
            },
            {
              title: "Date",
              dataIndex: "date",
              render(value) {
                return <span>{new Date(value).toLocaleDateString()}</span>;
              },
            },
          ]}
        />
      </Modal>
    </div>
  );
};
