import { useMutation, useQuery } from "@tanstack/react-query";
import { addAdmin, deleteAdmin, getAllAdmins, updateAdmin } from "../utils/api";
import {
  Table,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  message,
  Popconfirm,
  Space,
  Col,
  Row,
  Card,
} from "antd";

import { useState, useEffect } from "react";
import { useAuth } from "../utils/store";

const Admin = () => {
  const [messageApi, contextHolder] = message.useMessage();
  const user = useAuth((state) => state.user);
  console.log(user);
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["admins"],
    queryFn: () => (user?.getAdmin ? getAllAdmins() : []),
  });

  //   "adminValues": false,
  //   "getUsers": false,
  //   "addUsers": false,
  //   "updateUsers": false,
  //   "deleteUsers": false,
  //   "userDownloads": false,
  //   "userSoldRecords": false,
  //   "userPurchaseRecord": false,
  //   "userWalletHistory": false,
  //   "getCategory": false,
  //   "addCategory": false,
  //   "updateCategory": false,
  //   "deleteCategory": false,
  //   "getPhotos": false,
  //   "addPhotos": false,
  //   "updatePhotos": false,
  //   "deletePhotos": false,
  //   "getVideos": false,
  //   "addVideos": false,
  //   "updateVideos": false,
  //   "deleteVideos": false,
  //   "todayTransactions": false,
  //   "photoSoldRecords": false,
  //   "photoPurchaseRecords": false,
  //   "videoSoldRecords": false,
  //   "videoPurchaseRecords": false,
  //   "getAdmin": false,
  //   "addAdmin": false,
  //   "uploadAccess": false,

  const values = {
    adminValues: "Admin Values",
    getUsers: "Get Users",
    addUsers: "Add Users",
    updateUsers: "Update Users",
    deleteUsers: "Delete Users",
    userDownloads: "User Downloads",
    userSoldRecords: "User Sold Records",
    userPurchaseRecord: "User Purchase Record",
    userWalletHistory: "User Wallet History",
    getCategory: "Get Category",
    addCategory: "Add Category",
    updateCategory: "Update Category",
    deleteCategory: "Delete Category",
    getPhotos: "Get Photos",
    deleteAdmin: "Delete Admin",
    addPhotos: "Add Photos",
    updatePhotos: "Update Photos",
    deletePhotos: "Delete Photos",
    getVideos: "Get Videos",
    addVideos: "Add Videos",
    updateVideos: "Update Videos",
    deleteVideos: "Delete Videos",
    todayTransactions: "Today Transactions",
    photoSoldRecords: "Photo Sold Records",
    photoPurchaseRecords: "Photo Purchase Records",
    videoSoldRecords: "Video Sold Records",
    videoPurchaseRecords: "Video Purchase Records",
    getAdmin: "Get Admin",
    addAdmin: "Add Admin",
    uploadAccess: "Upload Access",
  };
  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => await deleteAdmin(values),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );

  const columns = [
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            <AddData
              update2={record}
              refech={refetch}
              values={Object.keys(values).map((key) => ({
                key: key,
                value: values?.[key],
              }))}
            />
            {user?.updateAdmin && (
              <AddData
                update2={record}
                refech={refetch}
                values={Object.keys(values).map((key) => ({
                  key: key,
                  value: values?.[key],
                }))}
              />
            )}
            {user?.deleteAdmin && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Email",
      dataIndex: "email",
    },
  ];

  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Admin
        </Typography>
        {user?.addAdmin && (
          <AddData
            refech={refetch}
            values={Object.keys(values).map((key) => ({
              key: key,
              value: values?.[key],
            }))}
          />
        )}
      </div>
      <Table
        footer={
          user?.getAdmin
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 500 }}
      />
    </div>
  );
};

export default Admin;

const AddData = ({ refech, values, update2 }) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [update, setUpdate] = useState(null);
  const { mutate, isLoading } = useMutation(
    async (values) =>
      update ? await updateAdmin(update._id, values) : await addAdmin(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();

        refech();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );
  const values2 = Form.useWatch([], form);
  useEffect(() => {
    if (update2) {
      setUpdate(update2);
    }
  }, [update2]);
  return (
    <div>
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Admin" : "Add Admin"}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update2
              ? update2
              : Object.fromEntries(values.map((value) => [value.key, false]))
          }
          onFinish={(values) => {
            mutate(values);
          }}
        >
          <Form.Item
            label="Email"
            name="email"
            required
            rules={[{ required: true, message: "Please input your Email!" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true, message: "Please input !" }]}
            label="Password"
            name="password"
            required
          >
            <Input.Password />
          </Form.Item>
          <Space style={{ justifyContent: "space-between" }}>
            <Typography
              style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
            >
              Permissions
            </Typography>
            <Switch
              //all and none
              checkedChildren="All"
              unCheckedChildren="None"
              defaultChecked={
                values.filter((value) => {
                  return update ? update[value.key] : false;
                }).length === values.length
              }
              onChange={(value2) => {
                form.setFieldsValue(
                  Object.fromEntries(values.map((value) => [value.key, value2]))
                );
              }}
              checked={
                values?.filter((value) => {
                  return values2?.[value.key] || false;
                }).length === values.length
              }
            />
          </Space>
          <Row gutter={[16, 16]}>
            {values?.map((value) => {
              return (
                <Col span={12} key={value}>
                  <Card>
                    <Form.Item label={value.value} name={value.key} required>
                      <Switch
                        defaultChecked={update ? update[value.key] : false}
                        checked={values2?.[value.key] || false}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              );
            })}
          </Row>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
