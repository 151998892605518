import { Button, Card, Checkbox, Divider, Input, message, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { UploadOutlined } from "@ant-design/icons";
import { addUserNotification, getUsers, userSerach } from "../utils/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useAuth } from "../utils/store";
const CheckboxGroup = Checkbox.Group;

const Notification = () => {
  const [loading, setLoading] = React.useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [notification, setNotification] = React.useState({});
  const [users, setUsers] = React.useState([]);
  const user = useAuth((state) => state.user);
  const {
    data,
    isLoading: isLoadingUsers,
    refetch,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => (user?.getUsers ? getUsers() : []),
  });

  const { mutate, isLoading } = useMutation(
    (data) => addUserNotification(data),
    {
      onSuccess: (data) => {
        messageApi.success("Notification added successfully");
      },
    }
  );

  const { mutate: searchHandler, isLoading: isLoadingSearch } = useMutation(
    (data) => userSerach(data),
    {
      onSuccess: (data) => {
        setUsers(
          data?.users?.map((user) => ({
            label: user.name,
            value: user._id,
          })) || []
        );
      },
    }
  );

  const addUserNotifications = () => {
    if (
      !notification?.title ||
      !notification?.description ||
      !notification?.image ||
      !notification?.users?.length > 0
    )
      return messageApi.error("Please fill all the fields");

    const formData = new FormData();
    formData.append("title", notification.title);
    formData.append("description", notification.description);
    formData.append("image", notification.image);
    formData.append("users", JSON.stringify(notification.users));

    mutate(formData);
  };

  useEffect(() => {
    if (data?.users) {
      setUsers(
        data?.users?.map((user) => ({
          label: user.name,
          value: user._id,
        })) || []
      );
    }
  }, [data]);

  return (
    <main
      style={{
        padding: "20px",
      }}
    >
      {contextHolder}
      <Card title="Notification" className="notification_card">
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          }}
        >
          <Input
            placeholder="Title"
            onChange={(e) => {
              setNotification({ ...notification, title: e.target.value });
            }}
          />
          <Input
            placeholder="Description"
            onChange={(e) => {
              setNotification({ ...notification, description: e.target.value });
            }}
          />
          <Upload
            onChange={(e) => {
              setNotification({ ...notification, image: e.file.originFileObj });
            }}
          >
            <Button icon={<UploadOutlined />}>Upload</Button>
          </Upload>
          <Divider />
          <h3>Users</h3>
          <Input.Search
            onSearch={(value) => {
              searchHandler({
                limit: 20,
                page: 1,

                searchType: "Name",
                searchQuery: value,
              });
            }}
            loading={isLoadingSearch || isLoadingUsers}
          />
          <CheckboxGroup
            options={users || []}
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
            value={notification?.users}
            onChange={(value) => {
              setNotification({ ...notification, users: value });
            }}
          />
        </div>
        <Button
          onClick={addUserNotifications}
          loading={isLoading}
          type="primary"
          style={{ marginTop: "20px" }}
        >
          Send
        </Button>
      </Card>
    </main>
  );
};

export default Notification;
