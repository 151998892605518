import { DesktopOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import { Button, Dropdown, Layout, Menu, Modal, Space, theme } from "antd";
import {
  BadgeIndianRupee,
  Contact,
  Film,
  Focus,
  ImageDown,
  ImagePlus,
  LayoutPanelLeft,
  Receipt,
  SquareStack,
  UploadIcon,
  Video,
  Videotape,
} from "lucide-react";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../utils/store";

const { Header, Content, Footer, Sider } = Layout;

function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

const menuItems = [
  getItem("Home", "/", <LayoutPanelLeft size={16} />),
  getItem("Users", "/users", <Contact size={16} />),
  getItem("Category", "/category", <SquareStack size={16} />),
  getItem("Photo", "/photos", <Focus size={16} />),
  getItem("Video", "/video", <Video size={16} />),
  getItem(
    "Todays Transactions",
    "/todays-transaction",
    <BadgeIndianRupee size={16} />
  ),
  getItem(
    "Photo Purchase Records",
    "/photos-transaction/purchase",
    <ImagePlus size={16} />
  ),
  getItem(
    "Photo Sold Records",
    "/photos-transaction/sold",
    <ImageDown size={16} />
  ),
  getItem(
    "Video Purchase Records",
    "/video-transaction/purchase",
    <Videotape size={16} />
  ),
  getItem("Video Sold Records", "/video-transaction/sold", <Film size={16} />),
  getItem("Admin", "/admin", <Film size={16} />),
  getItem("Notification", "/add-notification", <DesktopOutlined />),
  getItem("Upload", "/upload", <UploadIcon size={16} />),
  getItem("Redeem Requests", "/redeem-requests", <Receipt size={16} />),
];

const externalLinks = [
  {
    label: "App",
    url: "https://play.google.com/store/apps/details?id=com.swiss.stocks",
    type: "primary",
  },
  {
    label: "Website",
    url: "https://www.swissstocks.in/",
    type: "primary",
  },
  {
    label: "Analytics",
    url: "https://console.firebase.google.com/project/swiss-stocks-5e6b9/analytics/app/android:com.swiss.stocks/overview",
    type: "primary",
  },
];

const MainLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const router = useNavigate();
  const pathname = useLocation().pathname;
  const user = useAuth((state) => state.user);
  const setUser = useAuth((state) => state.setUser);

  // Responsive check
  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Redirect if no user
  useEffect(() => {
    if (!user) {
      router("/login");
    }
  }, [user]);

  // Mobile Menu
  const mobileMenu = (
    <Menu
      theme="dark"
      selectedKeys={pathname === "/" ? ["/"] : [pathname]}
      mode="vertical"
      items={menuItems}
      onClick={({ key }) => {
        router(key);
        setMobileMenuVisible(false);
      }}
    />
  );

  // External Links Dropdown
  const linksDropdown = externalLinks.map((link) => ({
    key: link.label,
    label: (
      <Link to={link.url} target="_blank">
        <Button type={link.type}>{link.label}</Button>
      </Link>
    ),
  }));

  return (
    <Layout style={{ minHeight: "100vh" }}>
      {isMobile ? (
        // Mobile Layout
        <>
          <Header
            style={{
              padding: "0 20px",
              background: "#001529",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img
              src="/logo.png"
              style={{
                height: 40,
                borderRadius: 5,
                backgroundColor: "white",
              }}
            />
            <Space>
              <Dropdown menu={{ items: linksDropdown }} placement="bottomRight">
                <Button type="primary">Links</Button>
              </Dropdown>
              <Button type="primary" danger onClick={() => setUser(null)}>
                Logout
              </Button>
              <Button type="primary" onClick={() => setMobileMenuVisible(true)}>
                <MenuUnfoldOutlined />
              </Button>
            </Space>
          </Header>

          <Modal
            title="Menu"
            open={mobileMenuVisible}
            onCancel={() => setMobileMenuVisible(false)}
            footer={null}
            bodyStyle={{ padding: 0 }}
          >
            {mobileMenu}
          </Modal>

          <Content style={{ margin: "16px" }}>{children}</Content>
        </>
      ) : (
        // Desktop Layout
        <Layout>
          <Sider
            collapsible
            collapsed={collapsed}
            onCollapse={(value) => setCollapsed(value)}
          >
            <img
              src="/logo.png"
              style={{
                height: 40,
                margin: 10,
                borderRadius: 5,
                backgroundColor: "white",
              }}
            />

            <Menu
              theme="dark"
              selectedKeys={pathname === "/" ? ["/"] : [pathname]}
              mode="inline"
              items={menuItems}
              onClick={({ key }) => {
                router(key);
              }}
            />
          </Sider>
          <Layout>
            <Header style={{ padding: 0, background: "#001529" }}>
              <Space style={{ float: "right", marginRight: 20 }}>
                {externalLinks.map((link) => (
                  <Link
                    key={link.label}
                    to={link.url}
                    target="_blank"
                    type="primary"
                  >
                    <Button type={link.type}>{link.label}</Button>
                  </Link>
                ))}
                <Button
                  type="primary"
                  danger
                  onClick={() => {
                    setUser(null);
                  }}
                >
                  Logout
                </Button>
              </Space>
            </Header>
            <Content>{children}</Content>
          </Layout>
        </Layout>
      )}
    </Layout>
  );
};

export default MainLayout;
