import React, { useEffect } from "react";

import { Button, Form, Upload, message } from "antd";
import { UploadCloudIcon, UploadIcon } from "lucide-react";
import { useMutation } from "@tanstack/react-query";
import { upload } from "../utils/api";

const UserUploadDataFirebase = ({ type, update, name, label }) => {
  const [file, setFile] = React.useState(null);
  const [date, setDate] = React.useState("0");
  const [isLoading, setIsLoading] = React.useState(false);
  useEffect(() => {
    setDate(Date.now());
  }, []);

  console.log(date);
  return (
    <Form.Item
      label={label}
      name={name}
      required
      rules={
        !update
          ? [
              {
                required: true,
                message: "Please input !",
              },
            ]
          : []
      }
    >
      <Upload
        accept={
          type === "photos"
            ? "image/*"
            : type === "videos"
            ? "video/*"
            : type === "audios"
            ? "audio/*"
            : type === "documents"
            ? "application/pdf"
            : ""
        }
        maxCount={1}
        // progress={{
        //   strokeColor: {
        //     "0%": "#108ee9",
        //     "100%": "#87d068",
        //   },
        //   strokeWidth: 3,
        //   format: (percent) => `${parseFloat(percent.toFixed(2))}%`,
        // }}
        // onRemove={async (file) => {
        //   const desertRef = ref(storage, `${type}/${date}${file.name}`);
        //   await deleteObject(desertRef);
        // }}
        customRequest={async ({ file, onSuccess, onProgress, onError }) => {
          const formData = new FormData();
          formData.append("file", file);
          setIsLoading(true);
          try {
            const data = await upload(formData);
            onSuccess({
              url: data?.[0],
            });
          } catch (error) {
            message.error(error?.response?.data?.message || "Error");
            onError(error);
          }
          setIsLoading(false);
        }}
        onChange={({ file, fileList }) => {
          console.log(file, fileList);
        }}
      >
        <Button
          loading={isLoading}
          type="dashed"
          icon={<UploadIcon size={16} />}
        >
          Upload {label}
        </Button>
      </Upload>
    </Form.Item>
  );
};

export default UserUploadDataFirebase;

// () => {
//   getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
//     onSuccess({ url: downloadURL });
//   });
// }
