import { useMutation, useQuery } from "@tanstack/react-query";

import {
  Table,
  Image,
  Typography,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  message,
  Popconfirm,
  Space,
  Select,
  Upload,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useAuth } from "../utils/store";
import {
  addCategory,
  deleteCategory,
  getCategories,
  getTodayTransactions,
  getTransactions,
  updateCategory,
} from "../utils/api";
import useSearch from "../components/Search";

const TodayTransaction = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["todays-transactions"],
    queryFn: () => user?.todayTransactions && getTodayTransactions(),
  });

  const search = useSearch();

  console.log(data);

  const photosColumns = [
    {
      title: "Image",
      dataIndex: ["photo", "low"],

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "User",
      dataIndex: ["user", "name"],
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phoneNumber"],
    },

    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render(value, record) {
        //date with time
        return (
          <span>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </span>
        );
      },
    },
  ];
  const videoColumns = [
    {
      title: "Image",
      dataIndex: ["video", "thumbnail"],

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "User",
      dataIndex: ["user", "name"],
    },
    {
      title: "Phone Number",
      dataIndex: ["user", "phoneNumber"],
    },

    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render(value, record) {
        //date with time
        return (
          <span>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </span>
        );
      },
    },
  ];
  console.log(user);
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Today's Transaction
        </Typography>
      </div>

      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Photo Purchase
          </Typography>
        )}
        columns={[
          ...photosColumns,
          {
            title: "Amount (in ₹)",
            dataIndex: "amount",
            render(value, record) {
              return <span>{record.amount + record.charges}</span>;
            },
          },
        ]}
        dataSource={data?.photoPurchase}
        loading={isLoading}
        scroll={{ x: 600 }}
        footer={
          user?.todayTransactions
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Photo sold
          </Typography>
        )}
        columns={[
          ...photosColumns,
          {
            title: "Amount (in ₹)",
            dataIndex: "amount",
            render(value, record) {
              return <span>{record.amount - record.charges}</span>;
            },
          },
        ]}
        dataSource={data?.photoSold}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Video Purchase
          </Typography>
        )}
        columns={[
          ...videoColumns,
          {
            title: "Amount",
            dataIndex: "amount",
            render(value, record) {
              return <span>{record.amount + record.charges}</span>;
            },
          },
        ]}
        dataSource={data?.videoPurchase}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Video Sold
          </Typography>
        )}
        columns={[
          ...videoColumns,
          {
            title: "Amount",
            dataIndex: "amount",
            render(value, record) {
              return <span>{record.amount - record.charges}</span>;
            },
          },
        ]}
        dataSource={data?.videoSold}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default TodayTransaction;
