import { useMutation, useQuery } from "@tanstack/react-query";

import { Table, Image, Typography, Button, message } from "antd";

import { Link } from "react-router-dom";

import { useAuth } from "../utils/store";
import { deleteCategory, getTransactions } from "../utils/api";
import useSearch from "../components/Search";

const Transaction = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["transactions"],
    queryFn: () => getTransactions(),
  });

  const search = useSearch();

  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => await deleteCategory(values),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );

  console.log(data);

  const photosColumns = [
    {
      title: "Image",
      dataIndex: ["photo", "low"],

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Amount (in ₹)",
      dataIndex: "amount",
    },

    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render(value, record) {
        //date with time
        return (
          <span>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </span>
        );
      },
    },
  ];
  const videoColumns = [
    {
      title: "Image",
      dataIndex: ["video", "thumbnail"],

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Amount (in ₹)",
      dataIndex: "amount",
    },

    {
      title: "Transaction Date",
      dataIndex: "createdAt",
      render(value, record) {
        //date with time
        return (
          <span>
            {new Date(value).toLocaleDateString()}{" "}
            {new Date(value).toLocaleTimeString()}
          </span>
        );
      },
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Transaction
        </Typography>
        <Link to="/todays-transaction">
          <Button type="primary">Today's Transaction</Button>
        </Link>
      </div>

      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Photo Purchase
          </Typography>
        )}
        columns={photosColumns}
        dataSource={data?.photoPurchase}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Photo sold
          </Typography>
        )}
        columns={photosColumns}
        dataSource={data?.photoSold}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Video Purchase
          </Typography>
        )}
        columns={videoColumns}
        dataSource={data?.videoPurchase}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
      <Table
        title={() => (
          <Typography
            style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}
          >
            Video Sold
          </Typography>
        )}
        columns={videoColumns}
        dataSource={data?.videoSold}
        loading={isLoading}
        scroll={{ x: 600 }}
      />
    </div>
  );
};

export default Transaction;
