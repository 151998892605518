import { useMutation, useQuery } from "@tanstack/react-query";

import { UploadOutlined } from "@ant-design/icons";
import {
  Button,
  Form,
  Image,
  Input,
  message,
  Modal,
  Popconfirm,
  Table,
  Typography,
  Upload,
} from "antd";

import { useState } from "react";
import useSearch from "../components/Search";
import {
  addCategory,
  deleteCategory,
  getCategories,
  updateCategory,
} from "../utils/api";
import { useAuth } from "../utils/store";

const Category = () => {
  const user = useAuth((state) => state.user);
  const [messageApi, contextHolder] = message.useMessage();
  const { data, isLoading, refetch } = useQuery({
    queryKey: ["categories"],
    queryFn: () => user?.getCategory && getCategories(),
  });

  const search = useSearch();

  const { mutate, isLoading: isLoading2 } = useMutation(
    async (values) => user?.deleteCategory && (await deleteCategory(values)),
    {
      onSuccess: () => {
        messageApi.success(`Deleted`);
        refetch();
      },
    }
  );

  const columns = [
    {
      title: "S.no",
      dataIndex: "sno",

      render(value, record, index) {
        return index + 1;
      },
    },
    {
      title: "Actions",
      dataIndex: "action",
      render(value, record) {
        return (
          <div>
            {user?.updateCategory && (
              <AddData update={record} refech={refetch} />
            )}
            {user?.deleteCategory && (
              <Popconfirm
                title="Delete the task"
                description="Are you sure to delete this task?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => mutate(record._id)}
              >
                <Button danger style={{ marginTop: 10 }} loading={isLoading2}>
                  Delete
                </Button>
              </Popconfirm>
            )}
          </div>
        );
      },
    },
    {
      title: "Image",
      dataIndex: "image",

      render(value, record) {
        return <Image src={value} height={100} width={100} />;
      },
    },
    {
      title: "Name",
      dataIndex: "name",
      ...search("name"),
    },

    {
      title: "slug",
      dataIndex: "slug",
    },
  ];
  return (
    <div
      style={{
        padding: 24,
      }}
    >
      {contextHolder}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: 20,
        }}
      >
        <Typography style={{ fontSize: 20, fontWeight: 600, marginBottom: 10 }}>
          Category
        </Typography>
        {user?.addCategory && <AddData refech={refetch} />}
      </div>
      <Table
        columns={columns}
        dataSource={data}
        loading={isLoading}
        scroll={{ x: 600 }}
        footer={
          user?.getCategory
            ? () => null
            : () => (
                <Typography.Text type="danger" strong>
                  You don't have permission to view this page
                </Typography.Text>
              )
        }
      />
    </div>
  );
};

export default Category;

const AddData = ({
  refech,

  update,
}) => {
  const [messageApi, contextHolder] = message.useMessage();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const type = Form.useWatch("rewardType", form);

  const { mutate, isLoading } = useMutation(
    async (values) =>
      update
        ? await updateCategory(update._id, values)
        : await addCategory(values),
    {
      onSuccess: () => {
        messageApi.success(`Added`);
        //reset form
        !update && form.resetFields();
        refech();
        setVisible(false);
      },
      onError: (err) => {
        messageApi.error(err?.response?.data?.message);
      },
    }
  );

  return (
    <div>
      {contextHolder}
      <Button type="primary" onClick={() => setVisible(true)}>
        {update ? "Update" : "Add"}
      </Button>
      <Modal
        title={update ? "Update Category" : "Add Category "}
        open={visible}
        onCancel={() => setVisible(false)}
        footer={null}
      >
        <Form
          form={form}
          labelCol={{ span: 6 }}
          layout="horizontal"
          style={{
            maxWidth: 600,
            padding: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
          initialValues={
            update && {
              name: update.name,
              type: update.type,
            }
          }
          onFinish={(values) => {
            console.log(values);

            const data = new FormData();

            if (values.image) {
              data.append("image", values.image.file.originFileObj);
            }
            data.append("name", values.name);

            mutate(data);
          }}
        >
          {" "}
          <Form.Item
            label="Image"
            name="image"
            required
            rules={
              !update
                ? [
                    {
                      required: true,
                      message: "Please input your image!",
                    },
                  ]
                : []
            }
          >
            <Upload name="image">
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
          <Form.Item
            label="Name"
            name="name"
            required
            rules={[
              {
                required: true,
                message: "Please input your name",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
