import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Button, message, Space, Spin, Table, Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { acceptDenyRedeemRequest, getRedeemRequest } from "../utils/api";
import { useAuth } from "../utils/store";

const RedeemRequest = () => {
  const navigate = useNavigate();
  const user = useAuth((state) => state.user);
  const queryClient = useQueryClient();

  const { data: requests, isLoading } = useQuery({
    queryKey: ["redeemRequests"],
    queryFn: async () => {
      const [success, data] = await getRedeemRequest();
      if (success) return data;
      throw new Error("Failed to fetch requests");
    },
  });

  const mutation = useMutation({
    mutationFn: ({ id, request }) => acceptDenyRedeemRequest(id, request),
    onSuccess: () => {
      queryClient.invalidateQueries(["redeemRequests"]);
      message.success("Request updated successfully");
    },
    onError: () => {
      message.error("Failed to update request");
    },
  });

  const handleAction = (id, status) => {
    mutation.mutate({
      id,
      request: {
        status,
        message: `Request has been ${status.toLowerCase()}`,
        userId: id,
      },
    });
  };

  const columns = [
    { title: "Created At", dataIndex: "createdAt", key: "createdAt" },
    { title: "Status", dataIndex: "status", key: "status" },
    { title: "User Name", dataIndex: ["user", "name"], key: "userName" },
    { title: "User Email", dataIndex: ["user", "email"], key: "userEmail" },
    { title: "UPI", dataIndex: ["user", "upi"], key: "upi" },
    { title: "Bank Name", dataIndex: ["user", "bankName"], key: "bankName" },
    { title: "IFSC", dataIndex: ["user", "ifsc"], key: "ifsc" },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <Space direction="vertical">
          <Button
            type="primary"
            onClick={() => handleAction(record._id, "Completed")}
          >
            Approve
          </Button>
          <Button danger onClick={() => handleAction(record._id, "Decline")}>
            Decline
          </Button>
        </Space>
      ),
    },
  ];

  React.useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  if (isLoading) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Spin size="large" tip="Loading requests..." />
      </div>
    );
  }

  return (
    <div
      style={{
        padding: "1rem",
        maxWidth: "1200px",
        margin: "0 auto",
      }}
    >
      <Tabs
        defaultActiveKey="1"
        items={[
          {
            key: "1",
            label: "New Requests",
            children: (
              <Table
                columns={columns}
                dataSource={requests?.created}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
          {
            key: "2",
            label: "Completed",
            children: (
              <Table
                columns={columns.filter((col) => col.key !== "actions")}
                dataSource={requests?.completed}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
          {
            key: "3",
            label: "Declined",
            children: (
              <Table
                columns={columns.filter((col) => col.key !== "actions")}
                dataSource={requests?.decline}
                rowKey="_id"
                pagination={{ pageSize: 5 }}
                scroll={{ x: true }}
              />
            ),
          },
        ]}
      />
    </div>
  );
};

export default RedeemRequest;
