import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "@tanstack/react-query";
import { Button, Input, Modal, Space, Table, Tabs, message } from "antd";
import React, { useState } from "react";

import {
  getAllRedeemRequest,
  getAllUploads,
  redeemRequest,
  updateUpload,
} from "../utils/api";
import { useAuth } from "../utils/store";
const Upload = () => {
  const { data, refetch } = useQuery({
    queryKey: ["upload-requests"],
    queryFn: getAllUploads,
  });

  console.log(data);

  const column = [
    {
      title: "User",
      dataIndex: ["user", "name"],
      key: "user",
    },
    {
      title: "Wallet",
      dataIndex: ["user", "wallet"],
      key: "wallet",
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },

    {
      title: "Link",
      dataIndex: "link",
      render(value, record) {
        return (
          <a href={value} target="_blank" rel="noreferrer">
            Link
          </a>
        );
      },
    },

    // {
    //   title: "Action",
    //   dataIndex: "action",
    //   key: "action",
    //   render: (value, record) => {
    //     return (
    //       <Space size="middle">
    //         <ApproveAndReject
    //           type={"Completed"}
    //           id={record}
    //           refetch={refetch}
    //         />
    //         <ApproveAndReject
    //           type={"Decline"}
    //           id={record}
    //           refetch={refetch}
    //         />
    //       </Space>
    //     );
    //   },
    // },
  ];

  return (
    <main
      style={{
        padding: "20px",
      }}
    >
      <>
        <Tabs
          defaultActiveKey="1"
          items={[
            {
              label: "Pending",
              key: "1",
              children: (
                <Table
                  dataSource={data?.filter((item) => item.status === "Pending")}
                  columns={[
                    ...column,
                    {
                      title: "Action",
                      dataIndex: "action",
                      key: "action",
                      render: (value, record) => {
                        return (
                          <Space size="middle">
                            <ApproveAndReject
                              type={"Approved"}
                              id={record}
                              refetch={refetch}
                            />
                            <ApproveAndReject
                              type={"Rejected"}
                              id={record}
                              refetch={refetch}
                            />
                          </Space>
                        );
                      },
                    },
                  ]}
                />
              ),
            },
            {
              label: "Approved",
              key: "2",
              children: (
                <Table
                  dataSource={data?.filter(
                    (item) => item.status === "Approved"
                  )}
                  columns={column}
                />
              ),
            },
            {
              label: "Rejected",
              key: "3",
              children: (
                <Table
                  dataSource={data?.filter(
                    (item) => item.status === "Rejected"
                  )}
                  columns={column}
                />
              ),
            },
          ]}
        />
      </>
    </main>
  );
};

export default Upload;

const ApproveAndReject = ({ id, type, refetch }) => {
  const [message1, setMessage1] = useState("");
  const [open, setOpen] = useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: (values) =>
      updateUpload(id._id, {
        status: type,
      }),
    onSuccess: () => {
      message.success(`${type} successfully`);
      refetch();
    },
  });

  return (
    <>
      <Button
        type="primary"
        onClick={() => {
          mutate();
        }}
        style={{
          background: type === "Approved" ? "green" : "red",
        }}
        loading={isPending}
      >
        {type}
      </Button>
      <Modal
        title={type}
        open={open}
        onOk={() => {
          mutate();
          setOpen(false);
        }}
        onCancel={() => setOpen(false)}
      >
        <Input.TextArea
          value={message1}
          onChange={(e) => setMessage1(e.target.value)}
          placeholder="Message"
        />
      </Modal>
    </>
  );
};
